@import '../../../assets/scss/BaseStyle/variables';

.app-footer {
  background-color: $primaryDark;
  color: $white;
  font-family: $baseReg;
  font-size: $baseFontSize;
  text-align: center;
  padding: 1rem 0;
}
