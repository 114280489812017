@import '../../../assets/scss/BaseStyle/variables';

.h-accordion {
  .accordion {
    position: relative;
  }

  .accordion-item {
    border: none;
    margin-bottom: 0;
  }

  .accordion-header {
    display: inline-block;
  }

  .accordion-button {
    background-color: $gray-300;
    color: $primary;
    font-size: 1.125rem;
    font-family: $baseMed;
    padding-top: 1.25rem;

    &::after {
      background-image: url('../../../assets/images/right-arrow.png');
      transform: initial;
      background-color: transparent;
    }

    &.collapsed {
      color: $gray-500;

      &::after {
        background-image: none;
      }
    }

    &:not(.collapsed) {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  @media (min-width: $mdDevices) {
    .accordion-collapse {
      position: absolute;
      top: 0;
    }
  }

  &.specialities {
    overflow-y: auto;

    .accordion-header {
      width: 14.375rem;
    }

    @media (min-width: $mdDevices) {
      .accordion-collapse {
        left: 14.375rem;
        width: calc(100% - 14.375rem);
      }

      .accordion-body {
        width: calc(100% - 14.375rem);
      }
    }

    .specialities-checkbox {
      label {
        padding-left: 1.2rem;
        margin-bottom: 1rem;
        display: block;

        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  }
}
