@import '../../../assets/scss/BaseStyle/variables';

.app-title {
  h2,
  h5 {
    color: $bodyTextColor;
  }

  h2 {
    font-size: $bigFontSize;
    font-family: $baseBold;
  }

  h5 {
    font-size: $mdFontSize;
    font-family: $baseMed;
    position: relative;
  }

  .horizontal-border {
    border-bottom: $borderLight;
    margin-left: 1rem;
    top: -15px;
    position: relative;
    opacity: 0.5;
  }

  &.with-trans {
    h5 {
      place-self: flex-end;
    }

    button {
      position: relative;
      top: 1rem;
    }
  }

  &.hide-horizontal-border {
    .horizontal-border {
      border-bottom: none;
    }
  }

  .minor-title {
    font-size: $baseFontSize;
    font-family: $baseReg;
    color: $gray-500;
  }

  &.text-primary {
    h5 {
      color: $primary;
    }
  }
}

.custom-title {
  background-color: $gray-200;
  display: flex;

  .right-side {
    margin-top: 1.75rem;
    margin-bottom: 6rem;
  }

  .page-head {
    @media (max-width: $smDevices) {
      padding-bottom: 0;
    }
  }
}
