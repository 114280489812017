@import '../../../assets/scss/BaseStyle/variables';

:root {
  --toastify-color-success: #fff;
  --toastify-icon-color-error: #fff;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
}

.Toastify__close-button > svg {
  color: #fff;
}

.Toastify__toast {
  padding: 15px;
  border-radius: 0;
}

.Toastify__toast-body {
  align-items: baseline;
  font-size: $baseFontSize;
  color: $white;
  font-family: $baseReg;
}

.Toastify__toast-icon {
  margin-right: 20px;
}

.Toastify__toast--success {
  background-color: $successDark;

  a {
    color: $white;
    text-decoration: underline;
  }
}

.Toastify__toast-container--bottom-left {
  bottom: 2.125rem;
  left: 3.75rem;
}

.Toastify__toast--error {
  background-color: #b43230;

  svg {
    color: $white;
  }
}
