@import '../../../assets/scss/BaseStyle/variables';

.app-navbar {
  border-bottom: $cardBorder;

  .navbar-nav {
    .dropdown-toggle {
      span {
        img {
          background-color: $primaryLightBg;
          border-radius: 100%;
          padding: 0.1rem 0.2rem;
          margin: 0 0.5rem;
          vertical-align: sub;
          width: 2.875rem;
          height: 2.688rem;
        }

        .user-data {
          display: inline-block;

          .user-name,
          .user-title {
            display: block;
          }

          .user-name {
            color: $bodyTextColor;
            font-size: $baseFontSize;
            font-family: $baseMed;
          }

          .user-title {
            font-family: $baseReg;
            font-size: 0.875rem;
            color: $gray-500;
          }
        }

        svg {
          margin: 0 1rem;
          position: relative;
          top: -4px;

          @media (max-width: $mdDevices) {
            display: none;
          }
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      @media (max-width: $mdDevices) {
        border: none;
      }

      &.show {
        top: 104%;
        border-radius: 0;
        border-top: 0;

        a {
          padding: 0.5rem 1rem;
          font-family: $baseReg;
          font-size: 0.875rem;
          color: $bodyTextColor;
          border-bottom: $borderLight;
          display: block;
          text-decoration: none;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
