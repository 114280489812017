@import '../../../../assets/scss/BaseStyle/variables';

textarea {
  border: $borderLight50;
  border-radius: $border-radius;
  color: $gray-700;
  font-family: $baseReg;
  font-size: $baseFontSize;
  width: 100%;
  padding: 0.688rem 0 0 1rem;

  &::placeholder {
    color: $gray-100;
  }

  &:focus,
  &:active {
    box-shadow: none;
    border: $borderWidth solid $primary;
    outline: none;
  }
}
