@import '../../../../assets/scss/BaseStyle/variables';

span {
  .label {
    color: $gray-500;
    font-size: $smallFontSize;
    font-family: $baseReg;
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;

    &.required {
      &::after {
        content: "*";
        color: $dangerDark;
        margin: 0 0.25rem;
      }
    }
  }
}
