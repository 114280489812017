@import '../../assets/scss/BaseStyle/variables';

.profile-card {
  .userImg {
    width: 7.5rem;
    height: 7.5rem;
    border: $borderWidth dashed $gray-100;
  }

  .username {
    font-size: $mdFontSize;
    font-family: $baseMed;
    color: $bodyTextColor;
  }

  .job-title {
    font-size: $smallFontSize;
    font-family: $baseReg;
    color: $gray-500;
  }

  .user-email {
    word-break: break-all;
  }

  svg {
    color: $gray-100 !important;
  }

  .border-top {
    border-top: $borderWidth solid $lightBoxShadow;
  }
}

.telegram-checkbox {
  color: $gray-100;
  padding-top: 14px;
  display: flex;

  .telegram {
    margin-top: 5px;
    width: 24px;
    height: 20px;
    border-radius: 2px;

    &.telegram-enabled {
      background-color: $gray-100;
      display: flex;
      align-content: center;
      justify-content: center;

      svg {
        color: $white !important;
        font-size: 10px;
        margin-top: 5px;
      }
    }

    &.telegram-disabled {
      border: 2px solid $gray-100;
    }
  }
}
