@import "../../assets/scss/BaseStyle/variables";

.login-container {
  background-image: url('../../assets/images/loginbg.png');
  min-height: 100vh;
  padding: 1rem;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-family: $baseBold;
    font-size: $bigFontSize;
    color: $bodyTextColor;
    text-shadow: $textShadow;
    text-align: center;

    span {
      color: $primary;
    }
  }

  .main-card {
    padding: 3rem 1.5rem;
    box-shadow: 0 0.5rem 4.375rem $gray-100;
    margin-top: 2rem;
    margin-bottom: 2rem;

    p {
      font-family: $baseReg;
      font-size: $baseFontSize;
      margin-bottom: 2rem;
    }

    h4 {
      font-family: $baseMed;
      font-size: $mdFontSize;
    }

    .alert-danger {
      h4,
      p {
        color: $bodyTextColor;
        text-align: center;
      }

      p {
        margin-bottom: 0;
      }
    }

    .btn {
      margin-left: 0;
    }

    svg {
      color: $gray-500;
      position: relative;
      display: inline-block;
      top: -2rem;
      left: 90%;
    }

    .checkbox-container {
      width: 50%;
      display: inline-block;

      @media (max-width: $mdDevices) {
        width: 100%;
        display: block;
        text-align: center;
      }
    }

    .forget-password {
      text-align: end;
      display: inline-block;
      width: 50%;

      @media (max-width: $mdDevices) {
        width: 100%;
        display: block;
        text-align: center;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .error-message + svg {
    top: -3.3rem;
  }

  .footer {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: $bodyTextColor;
    font-family: $baseReg;
    font-size: $baseFontSize;
  }
}
