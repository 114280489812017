/*
// Colors
// Dynamic Color
*/
$primary : #00b4aa;
$primaryDark : #009088;
$primaryLight : #4fc4bd;
$primaryLightBg: #d0f0ee;
$secondary : #58595b;
$secondaryDark : #444546;
$bodyTextColor: #161616;
$shadowColor: #f1f4f5;
$lightGray100: #9cb6c54d;
$transparent: transparent;
$lightBoxShadow: #9cb6c580;

/*
Static Color
*/
$dangerLight : #e57b7b;
$dangerMed: #e57b7b80;
$dangerDark : #b33030;
$successLight : #92e895;
$successMed: #92e89566;
$successDark : #63c667;
$infoLight : #c3e1ff;
$infoDark : #3195d4;
$warningLight : #ffef95;
$warningMed: #fcf0af80;
$warningDark : #efca00;
$complementray:#e64e3d;

// Color system
$white:    #fff !default;
// GREY LIGHT
$gray-100: #9cb6c5 !default;
// LIGHT BACKGROUND COLOR
$gray-200: #e5efef !default;
// BACKGROUND COLOR
$gray-300: #f1f5f8 !default;
$gray-400: #ced4da !default;
// GREY MEDIUM
$gray-500: #7390a0 !default;
$gray-600: #6c757d !default;
// DARK OVERLAY
$gray-700: #16161680 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

/*
Fonts
*/
$baseReg : "Roboto-Reg";
$baseMed : "Roboto-Med";
$baseBold : "Roboto-Bold";

/*
Font Size
*/
$smallFontSize:0.875rem;
$baseFontSize: 1rem;
$mdFontSize: 1.25rem;
$bigFontSize: 2rem;

/*
breakpoints
*/
$xsDevices:425px;
$smDevices: 576px;
$mdDevices: 768px;
$lgDevices: 992px;
$xlDevices: 1024px;
$xxlDevices: 1200px;
$xxxlDevices: 1440px;
$lDevices1366: 1366px;

$bigSmDevice: 600px;

/*
Borders
*/
$border-radius: 5px;
$borderWidth:1px;
$textShadow: 0 0 6rem $shadowColor;
$borderLight: $borderWidth solid $gray-100;
$borderLightDoted:$borderWidth dashed $gray-100;
$cardBorder: $borderWidth solid $lightGray100;
$borderLight50: $borderWidth solid $lightBoxShadow;

/*
Shadow
*/
$cardShadow: 0.5rem 0.5rem 2.5rem #7390a033;
$boxShadow: 0 1px 20px $lightBoxShadow;
