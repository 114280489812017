@import '../../../assets/scss/BaseStyle/variables';

button {
  &.btn {
    border-radius: 0;
    display: inline-block;
    width: auto;
    margin: 1rem;
    font-family: $baseReg;
    font-size: $baseFontSize;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-primary {
    background-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      background-color: $primaryDark;
      color: $white;
    }
  }

  &.btn-default {
    background-color: transparent;
    color: $secondary;

    &:hover,
    &:focus {
      background-color: $secondary;
      color: $white;
    }
  }

  &.btn-danger {
    background-color: $dangerDark;
    border-color: $dangerDark;
  }

  &.full-width {
    width: 100%;
  }

  &:disabled.btn-tooltip {
    pointer-events: auto;
  }
}
