@import '../../../../assets/scss/BaseStyle/variables';

.checkbox-container {
  input[type="checkbox"] {
    appearance: none;
    margin: 0 0.4rem;
    width: 1.25rem;
    background-color: $white;
    height: 1.25rem;
    border: $borderLight50;
    border-radius: 0.125rem;
    display: inline-grid;
    place-content: center;
    position: relative;
    top: 1px;

    &::before {
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1rem 1rem $white;
      border-radius: $border-radius;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"]:checked {
    background-color: $primary;

    &::before {
      transform: scale(1);
    }
  }

  label {
    color: $bodyTextColor;
    font-family: $baseReg;
    font-size: $baseFontSize;

    &.disabled {
      color: $gray-600;
    }
  }

  &.dark-16-base-checkbox label {
    color: $bodyTextColor;
    font-size: $baseFontSize;
    font-family: $baseReg;
  }
}
