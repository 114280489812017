@import '../../../assets/scss/BaseStyle/variables';

.app-loader {
  width: 98%;
  position: absolute;
  height: 100%;
  background: #ffffffad;
  z-index: 1;

  div {
    position: fixed;
    width: 500px;
    height: 500px;
    top: calc(50vh - 15.6rem);
    right: calc(50% - 15.6rem);
  }
}
