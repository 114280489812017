@import '../../../../assets/scss/BaseStyle/variables';

.date-clear-icon button.clear-btn {
  right: 37px;
}

.app-input {
  label,
  .label {
    color: $gray-500;
    font-family: $baseMed;
    font-size: $smallFontSize;
    display: block;
    margin-bottom: 0.5rem;

    &.required {
      &::after {
        content: "*";
        color: $dangerDark;
        margin: 0 0.25rem;
      }
    }
  }

  input {
    border: $borderLight50;
    border-radius: $border-radius;
    color: $bodyTextColor;
    font-family: $baseReg;
    font-size: $baseFontSize;
    height: 2.5rem;
    width: 100%;
    padding-left: 1rem;
    padding-right: 0.5rem;

    &::placeholder {
      color: $gray-100;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }

    &.has-error {
      border: $borderLight;
      border-color: $dangerDark;
    }

    ::-webkit-file-upload-button {
      background: $primary;
      color: $white;
      border: none;
      padding: 0.3rem 0.5rem;
      position: relative;
      top: 2px;
      font-family: $baseReg;
      text-align: center;
    }

    &[type="file"] {
      color: $gray-100;
      min-height: 2.8rem;
      padding-top: 0.1rem;
    }
  }

  span.minor-title + input {
    display: inline-block;
    width: auto;
    margin-left: 1rem;
  }

  .minor-title {
    font-family: $baseReg;
  }

  .hint {
    font-family: $baseReg;
    font-size: 0.75rem;
    color: $gray-100;
  }

  .dropzone {
    border: $borderLightDoted;
    padding: 1rem 0;
    cursor: pointer;

    .dropzone-placeholder {
      font-size: $baseFontSize;
      font-family: $baseReg;
      color: $gray-100;
      margin-bottom: 0;
      display: block;
      text-align: center;

      svg {
        margin: 0 0.5rem;
      }

      .text-primary {
        color: $primary;
      }
    }

    .hint {
      display: block;
      color: $gray-100;
      font-size: $smallFontSize;
      padding-left: 0.5rem;
    }
  }

  .clear-btn {
    padding: 0;
    margin: 0;
    right: 15px;
    top: 10px;
    position: absolute;
    font-size: $smallFontSize;
    color: $bodyTextColor;
    display: none;
  }

  &.focused {
    .clear-btn {
      display: inline;
    }
  }
}
