@import '../../../assets/scss/BaseStyle/variables';

.alert-danger {
  color: $dangerDark;
  font-family: $baseMed;

  svg {
    margin-right: 0.5rem;
  }

  .alert-heading {
    display: inline-block;
    width: calc(100% - 25px);
    font-family: $baseBold;

    &.h4 {
      font-size: $baseFontSize;
      display: flex;
    }
  }
}
