//font family
@font-face {
  font-family: Roboto-Reg;
  src: url("../../fonts//Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto-Med;
  src: url("../../fonts/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto-Bold;
  src: url("../../fonts/Roboto-Bold.ttf");
  font-display: swap;
}
