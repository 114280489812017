@import '../../../assets/scss/BaseStyle/variables';

.custom-link {
  &.primary-link {
    background: $primary;
    padding: 0.7rem;

    a {
      color: $white;
      text-decoration: none;
    }
  }

  &.secondary-link {
    background: $secondary;
    padding: 0.5rem 0.7rem;

    a {
      color: $white;
      text-decoration: none;
    }

    svg {
      margin: 0 0.5rem;
    }
  }

  .label {
    color: $primary;
    font-size: $baseFontSize;
    font-family: $baseReg;
  }
}
