@import '../../../../assets/scss/BaseStyle/variables';

div {
  select {
    height: 2.5rem;
    width: 100%;
    border: $borderLight50;
    border-radius: $border-radius;
    color: $bodyTextColor;
    font-family: $baseReg;
    font-size: $baseFontSize;
    padding-left: 1rem;
    background-color: transparent;

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
