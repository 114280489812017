@import '../../../../assets/scss/BaseStyle/variables';

.app-radio {
  &.custom-radio {
    display: inline-block;

    label {
      text-align: center;
      border: $borderLight50;
      padding: 0.5rem 0;
      border-radius: 0;
      color: $secondary;
    }

    span ~ div label {
      border-radius: $border-radius 0 0 $border-radius;
    }

    &.default-radio label {
      color: $gray-500;
      font-family: $baseReg;
      font-size: $baseFontSize;
      padding-left: 0.5rem;
    }

    div:last-child label {
      border-radius: 0 $border-radius $border-radius 0;
    }

    &.has-error {
      .label {
        border-color: $dangerDark;
      }
    }

    input {
      display: none;
    }

    label.checked {
      background-color: $primary;
      color: $white;
    }
  }

  &.default-radio {
    div span {
      display: inline-block;
    }

    input {
      float: left;
      position: relative;
      top: 0.4rem;
    }

    &.has-error {
      input {
        border-color: $dangerDark;
      }
    }

    input[type=radio] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    input[type=radio]::before {
      content: " ";
      display: inline-block;
      position: relative;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      top: -0.2rem;
      border: $borderLight50;
      background-color: transparent;
    }

    input[type=radio]:checked::before {
      border-color: $primary;
    }

    input[type=radio]:checked::after {
      content: " ";
      display: block;
      position: absolute;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      top: 0.15rem;
      left: 0.2rem;
      background: $primary;
    }
  }

  &.two-radio {
    div {
      display: inline-block;
      width: 50%;
    }
  }
}
