@import '../../assets/scss/BaseStyle/variables';

.error-page {
  &.error-404 {
    min-height: calc(100vh - 70px - 100px);
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    align-items: center;

    .container {
      width: 100%;

      img {
        width: 45%;
        margin-bottom: 2rem;

        @media (max-width: 1024px) {
          width: 70%;
        }
      }

      p {
        font-family: $baseMed;
        color: #58595b;
        font-size: $mdFontSize;
      }
    }
  }

  &.error-500 {
    height: 100vh;
    display: flex;
    align-items: center;
    background-image: url('../../assets/images/error500bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    @media (max-width: 1024px) {
      background-image: url('../../assets/images/error500smallbg.png');
      background-size: cover;
      background-position: initial;
      position: relative;

      &::before,
      &::after {
        position: absolute;
        width: 50%;
        background-repeat: no-repeat;
        content: '';
      }

      &::before {
        height: inherit;
        top: -1rem;
        left: 35%;
        background-image: url('../../assets/images/error500smalltop.png');
      }

      &::after {
        background-image: url('../../assets/images/error500smallbottom.png');
        left: 35%;
        height: 25%;
        bottom: 0;
      }
    }

    @media (max-width: 425px) {
      &::before {
        top: -8rem;
      }

      &::after {
        background-image: url('../../assets/images/error500smallbottom.png');
        left: -11%;
        height: 20%;
        bottom: 0;
        width: 77%;
      }
    }

    .page-text {
      padding-left: 10%;
      text-align: center;
      width: 50%;

      @media (max-width: 1024px) {
        width: 100%;
        padding: 0;
      }

      h1 {
        color: #58595b;
        font-family: "Roboto-Med", sans-serif;
        font-size: 3rem;
      }

      p {
        font-family: "Roboto-Reg", sans-serif;
        color: #9cb6c5;
        font-size: 1rem;
      }
    }
  }

  .primary-link {
    background: #00b4aa;
    padding: 0.7rem;
    color: white;
    text-decoration: none;

    a {
      color: white;
    }
  }
}
