@import "../../../../assets/scss/BaseStyle/variables";

.upload-style {
  ul {
    list-style: none;
    padding-left: 0.5rem;

    li {
      position: relative;
      font-size: $baseFontSize;
      font-family: $baseReg;
      color: $bodyTextColor;

      .error-icon {
        position: absolute;
        left: 90%;

        .fa-exclamation-circle {
          color: $dangerDark;
          margin: 0 0.5rem;
        }

        &.without-errors {
          left: calc(90% + 30px);
        }
      }

      .file-name {
        display: inline-block;
        width: 90%;
      }
    }

    &.file-error {
      padding-left: 0;

      li {
        color: $dangerDark;
        margin-bottom: 0.5rem;
        font-size: $smallFontSize;
        position: relative;

        .fa-exclamation-circle {
          color: $dangerDark;
          position: absolute;
          left: 90%;
        }
      }
    }

    .remove-item {
      color: $bodyTextColor;
      position: absolute;
      left: 100%;
      padding: 0;
      margin: 0;
      top: -0.1rem;
    }
  }
}
