@import '../../../assets/scss/BaseStyle/variables';

.app-accordion {
  .accordion-button {
    background-color: $gray-300;
    padding-left: 1.8rem;
    font-size: $mdFontSize;
    font-family: $baseMed;

    &::after {
      background-color: $white;
      border-radius: 0.188rem;
      background-size: 0.9rem;
      background-position: center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &:not(.collapsed) {
      box-shadow: none;
      color: $bodyTextColor;
    }

    &:focus {
      box-shadow: none;
      color: $bodyTextColor;
    }
  }

  .accordion-item {
    margin-bottom: 2rem;
    border: none;

    &:first-of-type,
    &:last-of-type {
      .accordion-button {
        border-radius: 0;
      }
    }
  }
}
